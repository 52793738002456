// Here you can add other styles
.form-control.is-invalid {
  background-image: none; // remove cross image
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.dropdown-menu {
  background-color: #40484f;
  border-color: #40484f;

  .dropdown-item {
    color: #fff;
  }

  .dropdown-item:hover {
    background-color: #fff;
    color: black;
  }
}

.tab-content {
  background-color: #14212f;
  color: #fff;
  border: 1px solid #0f1924 !important;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link.active {
  background-color: #14212f;
  border: 1px solid #0f1924 !important;
  color: #fff;
}
